<template>
<div style="width:100%">
  <v-list-item link class="pa-0">
    <v-row no-gutters>
      <v-col cols="12" md="1">
        <v-row no-gutters align="center">
            <v-col cols="12" md="3">
              <v-card width="25" height="100" color="pink" @click="abrirColores"></v-card>
            </v-col>
            <v-col cols="12" md="9" style="font-size:12px" class="text-center">
              <v-row no-gutters align="center">
                <v-col  class="text-center">
                  {{ item.codigo }}
                </v-col>
              </v-row>
              <v-row no-gutters align="center">
                <v-col  class="text-center">
                  <v-icon v-if="validarFecha(item)===1" :color="colores.green" class="parpadea">mdi-email</v-icon>
                  <v-icon v-else-if="validarFecha(item)===2" :color="colores.green">mdi-email</v-icon>
                </v-col>
                <v-col>
                  <v-icon v-if="estadoReplica(item)===1" :color="colores.amber" class="parpadea">mdi-content-duplicate</v-icon>
                </v-col>
              </v-row>
            </v-col>
        </v-row>
          <!-- <v-btn icon color="pink"><v-icon>mdi-heart</v-icon></v-btn> -->
      </v-col>
      <v-col cols="12" md="2">
        <v-list-item-content class="pa-0">
            <v-list-item-title v-if="item.aplicativo"><v-icon size="15"> {{ item.aplicativo.icono }} </v-icon><span style="font-size:10px" class="pl-2">{{ item.aplicativo.descripcion }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescliente.identificacion }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.gescliente.nombre }}</span></v-list-item-title>
            <v-list-item-title v-if="item.gescliente"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ (item.gescliente.sexo)?item.gescliente.sexo.descripcion:'' }}</span></v-list-item-title>

        </v-list-item-content>
      </v-col>
      <v-col cols="12" md="3">
          <v-list-item-content class="pa-0">
              <v-list-item-title v-if="item.tipo" ><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.tipo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.motivo"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.motivo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.submotivo"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.submotivo.descripcion }}</span></v-list-item-title>
              <v-list-item-title v-if="item.canal"><v-icon size="15">mdi-chevron-right</v-icon><span style="font-size:10px">{{ item.canal.descripcion }}</span></v-list-item-title>
          </v-list-item-content>
      </v-col>
      <v-col cols="12" md="3" v-if="estadoReplica(item)===1">
            <v-row no-gutters><v-col class="pl-2"><span class="pl-1" style="font-size:9px"><v-chip x-small :color="item.subestado.color" dark>ESTADO: <span class="pr-2"></span> {{ item.subestado.descripcion }} </v-chip></span></v-col></v-row>
            <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">{{datosReplicaActual.created_at.substr(0, 10)}}</span></v-col></v-row>
            <v-row no-gutters v-if="item.usuario"><v-col class="pl-2"><v-icon size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px">{{ item.usuario.nombre_usuario + '' + item.usuario.apellido_usuario }}</span></v-col></v-row>
            <v-row no-gutters ><v-col class="pr-2"><v-card class="pl-2" :color="colorVReplica" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ (datosReplicaActual.fechaparacerrarprorroga !== null )? datosReplicaActual.fechaparacerrarprorroga.substr(0, 10) : datosReplicaActual.fechaparacerrarinterno.substr(0, 10) }}</span></v-card></v-col></v-row>
      </v-col>
      <v-col cols="12" md="3" v-if="estadoReplica(item)===0">
            <v-row no-gutters><v-col class="pl-2"><span class="pl-1" style="font-size:9px"><v-chip x-small :color="item.subestado.color" dark>ESTADO: <span class="pr-2"></span> {{ item.subestado.descripcion }} </v-chip></span></v-col></v-row>
            <v-row no-gutters><v-col class="pl-2"><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">{{(item.aplicativo.id === 2) ? item.fecha_requerimiento.substr(0, 10) : item.fecha_requerimiento }}</span></v-col></v-row>
            <v-row no-gutters v-if="item.fechacerrado" ><v-col class="pl-2"><v-icon size="15" :color="item.subestado.color">mdi-calendar-multiple-check</v-icon><span class="pl-1" style="font-size:10px">{{ item.fechacerrado }}</span></v-col></v-row>
            <v-row no-gutters v-if="item.usuario"><v-col class="pl-2"><v-icon size="15">mdi-account-circle</v-icon><span class="pl-1" style="font-size:10px">{{ item.usuario.nombre_usuario + '' + item.usuario.apellido_usuario }}</span></v-col></v-row>
            <v-row no-gutters v-if="item.fechaparacerrarexpres !== null || item.fechaparacerrarinterno !== null"><v-col class="pr-2"><v-card class="pl-2" :color="colorVencido" dark><v-icon size="15">mdi-calendar</v-icon><span class="pl-1" style="font-size:10px">CERRAR {{ (item.quejaexpres_id===29)? ((item.fechaparacerrarexpres) ? item.fechaparacerrarexpres.substr(0, 10) : null): ((item.fechaparacerrarinterno) ? item.fechaparacerrarinterno.substr(0, 10) : null ) }}</span></v-card></v-col></v-row>
      </v-col>
      <v-col cols="12" md="3" align-self="center">
          <v-row no-gutters align="center">
            <v-col cols="12" md="10">
                <v-row no-gutters class="pt-1" v-for="(itemEsc, indexEsc) in item.escalamientos" :key="indexEsc">
                  <v-col>
                    <v-chip small outlined color="green">
                      <v-avatar left><v-icon size="15">mdi-check-bold</v-icon></v-avatar>
                      <span style="font-size:9px">{{ itemEsc.escaladoa.nombre_usuario + '' + itemEsc.escaladoa.apellido_usuario }}</span>
                    </v-chip>
                  </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="2" align-self="center" class="text-center">
              <v-btn @click="ver_pqr(item)" icon color="blue"><v-icon>mdi-pencil</v-icon></v-btn>
            </v-col>
          </v-row>
      </v-col>
    </v-row>
  </v-list-item>
  <div style="height:5px"></div>
  <v-divider></v-divider>
  <div style="height:5px"></div>
</div>
</template>

<script>
import recFunciones from '@/js/rec_funciones.js'
export default {
  name: 'bandeja_listados_crmComponent',
  components: {
  },
  mixins: [recFunciones],
  props: ['colores', 'item', 'session'],
  data: () => ({
    colorVencido: 'green',
    colorVReplica: 'green',
    datosReplicaActual: []
  }),
  created () {
    this.color()
  },
  methods: {
    ver_pqr (pItem) {
      this.$emit('view_crm', pItem)
    },
    abrirColores () {

    },
    validarFecha (item) {
      let showHoy = 0
      let showLog = 0
      for (let i = 0; i < item.geslogs.length; i++) {
        if (item.geslogs[i].gesotro !== null) {
          for (let j = 0; j < item.geslogs[i].gesotro.gesotrorespuestas.length; j++) {
            const nowDate = this.rec_funciones_fecha_actual()
            const mailDate = this.rec_funciones_fecha(new Date(item.geslogs[i].gesotro.gesotrorespuestas[j].fecha))
            if (nowDate === mailDate) {
              showHoy = 1
            } else {
              showLog = 1
            }
          }
        }
        for (let j = 0; j < item.geslogs[i].gesesclogs.length; j++) {
          for (let k = 0; k < item.geslogs[i].gesesclogs[j].gesesclogrespuesta.length; k++) {
            const nowDate = this.rec_funciones_fecha_actual()
            const mailDate = this.rec_funciones_fecha(new Date(item.geslogs[i].gesesclogs[j].gesesclogrespuesta[k].fecha))
            if (nowDate === mailDate) {
              showHoy = 1
            } else {
              showLog = 1
            }
          }
        }
      }
      if (showHoy === 1) {
        return 1
      } else if (showLog === 1) {
        return 2
      } else {
        return 0
      }
    },
    estadoReplica (item) {
      for (let i = 0; i < item.replicas.length; i++) {
        if (item.replicas[i].fechacerrado === null) {
          // this.datosReplicaActual = null
          this.datosReplicaActual = item.replicas[i]
          this.colorReplica()
          return 1
        }
      }
      return 0
    },
    color () {
      setInterval(() => {
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        today = today.toISOString().split('T')[0]
        const fecha = (this.item.quejaexpres_id === 29) ? this.item.fechaparacerrarexpres : this.item.fechaparacerrarinterno
        let dayago = new Date(fecha)
        dayago = dayago.toISOString().split('T')[0]
        dayago < today ? this.colorVencido = 'red' : this.colorVencido = 'green'
      }, 3000)
    },
    colorReplica () {
      setInterval(() => {
        let today = new Date()
        today.setHours(0, 0, 0, 0)
        today = today.toISOString().split('T')[0]
        const fecha = this.datosReplicaActual.fechaparacerrarinterno
        let dayago = new Date(fecha)
        dayago = dayago.toISOString().split('T')[0]
        dayago < today ? this.colorVReplica = 'red' : this.colorVReplica = 'green'
      }, 3000)
    }
  }
}
</script>
<style scoped>
.parpadea {
  animation-name: parpadeo;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  -webkit-animation-name:parpadeo;
  -webkit-animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
}
@-moz-keyframes parpadeo{
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@keyframes parpadeo {
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}
</style>
